import { useStaticQuery, graphql } from 'gatsby';

const useAllTeamsResultsTable = () => {
    const {
        allSanityTeamsResultsTable: { nodes: teamsResultsTable },
    } = useStaticQuery(graphql`
        query {
            allSanityTeamsResultsTable {
                nodes {
                    league
                    relatedTeam {
                        id
                    }
                    season {
                        title
                    }
                    teamsResults {
                        id
                        name
                        matchesPlayed
                        wins
                        draws
                        loses
                        goals
                        goalsDifferential
                        points
                        club {
                            image {
                                asset {
                                    _id
                                }
                            }
                            isHammarby
                            shortName
                        }
                    }
                }
            }
        }
    `);

    return teamsResultsTable;
};

export default useAllTeamsResultsTable;
