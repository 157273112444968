import * as React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/misc/banner.module.scss';
import CustomLink from 'components/utils/CustomLink';
import TextEditorRender from 'components/utils/TextEditorRender';

const renderBannerContent = (image, title, subtitle, description) =>
    <>
        {image && (
            <ImageGatsby
                {...image}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                alt={title}
            />
        )}
        <div className={`${style.banner__wrapper} wrapper`}>
            <div className={`${style.banner__inner}`}>
                <h1 className="title-md-mobile title-xl text-bold">{title}</h1>
                <p className="text-lg-mobile text-xl">{subtitle}</p>

                {description && (
                    <div className={style.banner__description}>
                        <TextEditorRender content={description} />
                    </div>
                )}
            </div>
        </div>
    </>;

const Banner = ({ image, title, subtitle, link, description }) =>
    <div className={style.banner}>
        {link && (
            <CustomLink to={link} className={style.banner__link}>
                {renderBannerContent(image, title, subtitle, description)}
            </CustomLink>
        )}

        {!link && renderBannerContent(image, title, subtitle, description)}
    </div>;

export default Banner;
