import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/match/resultsTable.module.scss';
import useAllTeamsResultsTable from 'data/queries/useTeamsResultsTable';
import { LangContext } from 'context/LangContext';
import { getFormattedMessage } from 'components/utils/FormattedMessage';

const ResultsTable = ({ teamId }) => {
    const { lang } = useContext(LangContext);

    const teamsResultsTable = useAllTeamsResultsTable();
    const table = teamsResultsTable.filter(item => item.relatedTeam.id === teamId)[0];

    if (!table) {
        return null;
    }

    // Multiple field sorter (fields that start with '-' -> reverse the sorting)
    const fieldSorter = (fields) => (currentVal, nextVal) => fields
        .map(field => {
            let direction = 1;
            if (field[0] === '-') {
                direction = -1;
                field = field.substring(1);
            }

            if (currentVal[field] > nextVal[field]) return direction;
            if (currentVal[field] < nextVal[field]) return -(direction);
            return 0;
        })
        .reduce((acc, curr) => acc || curr, 0);

    return (
        <div className={style.container}>
            <h2 className="text-xl text-center text-bold">
                {table.league} - {table.season.title}
            </h2>

            <table className={style.table}>
                <thead>
                <tr>
                    <th title={getFormattedMessage('match.resultsTableClub', lang)}>
                        {getFormattedMessage('match.resultsTableClub', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTablePlayed', lang)}>
                        {getFormattedMessage('match.resultsTablePlayedShort', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTableWins', lang)}>
                        {getFormattedMessage('match.resultsTableWinsShort', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTableDraws', lang)}>
                        {getFormattedMessage('match.resultsTableDrawsShort', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTableLoses', lang)}>
                        {getFormattedMessage('match.resultsTableLosesShort', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTableGoalsDiff', lang)}>
                        {getFormattedMessage('match.resultsTableGoalsDiffShort', lang)}
                    </th>
                    <th title={getFormattedMessage('match.resultsTablePoints', lang)}>
                        {getFormattedMessage('match.resultsTablePointsShort', lang)}
                    </th>
                </tr>
                </thead>

                <tbody>
                {table.teamsResults
                    .sort(fieldSorter(['-points', '-goalsDifferential', '-goals', 'name']))
                    .map((team) => {
                        const lineClass = team.club?.isHammarby ? 'highlighted' : '';
                        const clubName = team.club?.shortName || team.name;

                        return (
                            <tr key={team.id} className={`${style.line} ${lineClass}`}>
                                <td>
                                    {team.club?.image && (
                                        <ImageGatsby
                                            {...team.club.image}
                                            alt={clubName}
                                        />
                                    )}
                                    {clubName}
                                </td>
                                <td>{team.matchesPlayed}</td>
                                <td>{team.wins}</td>
                                <td>{team.draws}</td>
                                <td>{team.loses}</td>
                                <td>
                                    {team.goalsDifferential > 0 && (<>+</>)}
                                    {team.goalsDifferential}
                                </td>
                                <td>{team.points}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ResultsTable;
