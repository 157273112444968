import { useStaticQuery, graphql } from 'gatsby';

const useSeasons = () => {
    const {
        allSanitySeason: { nodes: seasons },
    } = useStaticQuery(graphql`
        query {
            allSanitySeason(sort: {fields: end, order: DESC}) {
                nodes {
                    _id
                    start
                    end
                    title
                }
            }
        }
    `);

    return seasons;
};

export default useSeasons;
